require('./bootstrap');
require('lazysizes');
require('slick-carousel');
require('./carousels');

$(document).ready(function() {
    var setDefaultActive = function() {
        var href = window.location.href;
        var path = window.location.pathname;

        if(path !== '/free-demo' && path !=='/ro/catalog-demo-gratis'){
            var element = $("ul.navbar-nav > li > a[href='" + href + "']");
            element.addClass("active");
            element.removeClass("nav-style");
        }
    }
    setDefaultActive();

    $("#buttonTestimonials").click(function() {
        $(this).hide();
      });

    // On scroll add custom class on navigation bar
    var $navbar = $("#navigationBar");

    AdjustHeader();
    $(window).scroll(function() {
        AdjustHeader();
    });

    function AdjustHeader(){
        if ($(window).scrollTop() > 60) {
            if (!$navbar.hasClass("custom-moving-header")) {
                $navbar.addClass("custom-moving-header");
            }
        } else {
            $navbar.removeClass("custom-moving-header");
        }
    }

    // Scroll up button
    // Get the button:
   var mybutton = document.getElementById("back_to_top");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > $(document).height()*0.5 || document.documentElement.scrollTop > $(document).height()*0.5) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    $("#back_to_top").click(function() {
        topFunction();
    });

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    // Change sub-menu button on toggle
    $('#menuButton').click(function(){
        var link = $("#menuShow");
        $('#collapsingSubMenu').toggle(function() {
            if ($(this).is(":visible")) {
                link.html('remove');
            } else {
                link.html('add');
            }
        });
    });

    // Change language-menu button on toggle
    $('#languageButton').click(function(){
        var link = $("#languageMenuShow");
        $('#languageSelector').toggle(function() {
            if ($(this).is(":visible")) {
                link.html('remove');
            } else {
                link.html('add');
            }
        });
    });


    if($('#contactCreated').length){
        $('#contactCreated').modal('toggle');
    }
    if($('#demoContactCreated').length){
        $('#demoContactCreated').modal('toggle');
    }

    $('[data-click-catalogue]').on('click', function (event) {
        var button = $(event.relatedTarget)
        var catalogueLink = $(this).data('info')
        var catalogueCloseText = $(this).data('closetext')

        var iframeHolder = $('#iframeHolder');
        iframeHolder.html('<div style="position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 999999999;">' +
            '<div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: rgba(0, 0, 0, 0.5);">' +
            '<button id="closeIframeButton" style="position: absolute; top: 0; left: 0; width: 100%; height: 30px; line-height: 30px; border: none; background: #fff; color: #000; text-align: center; font-size: 14px; cursor: pointer;">X - '+catalogueCloseText+'</button>' +
            '</div>' +
            '<iframe style="position: absolute; width: 100%; height: calc(100% - 30px); top: 30px; left: 0; border: none; background-color: #fff;" src='+catalogueLink+' allowfullscreen="true">' +
            '</iframe>' +
            '</div>');

        $("#closeIframeButton").on('click', function() {
            iframeHolder.html('');
        });
    });

    $('input[type="file"]').on('change', function(e){
        var fileName = e.target.files[0].name;
        if (fileName) {
            $(e.target).parent().attr('data-message', fileName);
            $('.added-text').css("display", "none");
        }
    });
    $('.added-text').focus().trigger('click');

    $(document).on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
        if ($('input[type="file"]').length) {
            if (['dragover', 'dragenter'].indexOf(e.type) > -1) {
                if (window.dragTimeout)
                    clearTimeout(window.dragTimeout);
                $('body').addClass('dragged');
            } else if (['dragleave', 'drop'].indexOf(e.type) > -1) {
                // Without the timeout, some dragleave events are triggered
                // when the :after appears, making it blink...
                window.dragTimeout = setTimeout(function() {
                    $('body').removeClass('dragged');
                }, 100);
            }
        }
    });

});

window.addEventListener('load', function () {
    var has_dcp = window.location.href.indexOf('?dcp=') !== -1;
    if (has_dcp) {

        var url = new URL(window.location.href),
            dcp = url.searchParams.get('dcp');

        if (dcp) {
            var $catalog_link = $('[data-click-remote="' + dcp + '"]');
            if ($catalog_link.length) {
                $catalog_link.click();
            }
        }
    }
});


